<template>
  <section id="Experiencia" class=" my-5 py-5" data-aos="fade-down">
    <div class="d-block d-lg-none">
      <div class="text-center">
        <h2><b>{{ data.title }}</b></h2>
        <h6>
          {{ data.subtitle }}
        </h6>
      </div>
      <img
        class="models-image w-100"
        src="@/assets/img/banner-equipo-y-experiencia-mobile.webp"
        alt="Imagen de comienzo de seccion equipo y experiencia version movil"
        loading="lazy"
      />
    </div>
    <div class="d-none d-lg-block position-relative">
      <img
        class="w-100 img-fluid"
        src="@/assets/img/banner-equipo-y-experiencia-desktop.webp"
        alt="Imagen de comienzo de seccion equipo y experiencia"
        loading="lazy"
      />

      <div class="DivBannerEjecutivo">
        <div class="DivBannerEjecutivoTexto">
          <h2><b>{{ data.title }}</b></h2>
          <h6>
            {{ data.subtitle }}
          </h6>
        </div>
      </div>

    </div>
  </section>
</template>


<script>
import store from '../../store';

export default {
  name: "EquipoBanner",
  data() {
    return {
      data: {
        title: 'Equipo y Experiencia',
        subtitle: 'Te invitamos a conocernos'
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      if (store.state.sections.teamExperience) {
        this.data = store.state.sections.teamExperience.components
      }
    }
  }
}
</script>

<style scoped lang="scss">
/* .teamBanner {
  height: 280px;
  width: 100%;
  position: relative;

  .__content {
    background-image: url("../../assets/img/left-grey-oval.png");
     background-size: contain; 
    background-repeat: no-repeat;
    background-position: bottom left; 
    height: 280px;
    max-width: 1400px;
    width: 100%;
    padding: 20px;
    position: relative;

    img {
      bottom: 0;
      position: absolute;
      height: 240px;
      max-height: 600px;
      right: 5%;
    }
  }
}

@media (min-width: 992px) {
  .teamBanner {
    align-items: center;
    display: flex;
    height: 580px;
    justify-content: flex-end;
    margin-top: 11rem;

    .__content {
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom left;
      display: flex;
      justify-content: flex-start;
      height: 380px;
      width: 100%;
      padding: 0 0 0 13%;

      .__subtitle {
        max-width: 30%;
      }

      img {
        height: auto;
      }
    }

    h2 {
      font-size: 3.1rem !important;
    }
  }
} */


.ModeloBanner {
  position: relative;
}

.DivBannerEjecutivo {
  position: absolute; /* posición absolute con respecto al padre */
  top: 13%;
  width: 40%;
  height: 59%;
  left: 12%;
  /*background-color: #575da6;*/
  /*padding: 1%;*/
}

.DivBannerEjecutivoTexto {
  padding: 1%;
  margin-top: 24%;
}

.models-image {
  margin-right: -1rem;
}
</style>
